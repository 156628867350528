<template>
    <div class="page">
        <div style="width: 100%;height: 400px;background-color: #1320E8"></div>
        <div class="container">
            <div style="max-width: 1000px;margin: 0 auto">
                <div class="active">
                    <div class="nav">
                        <img src="@/assets/common/路径 50@2x.png" alt=""
                             style="height: 14px;width: auto;margin-right: 20px;">
                        <div class="pointer" @click="goRoute('home')">首页</div>
                        <i class="el-icon-arrow-right"></i>
                        <div class="pointer" @click="goRoute('informationCenter')">资讯中心</div>
                        <i class="el-icon-arrow-right"></i>
                        <div>{{ $route.query.title }}</div>
                        <div class="shu"></div>
                        <div class="time" v-if="article">{{ article.news.createDate }}</div>
                    </div>

                    <div class="tit" v-if="article">{{ article.news.title }}</div>
                    <div class="cbox">
                        <div class="active-c" v-if="article" v-html="article.news.htmlContent"></div>
                    </div>
                    <div style="padding: 0" v-if="type!='home'">
                        <div class="nexpian-box">
                            <div class="nexpian" @click="goMore('p')">
                                <div style="margin-bottom: 40px">上一篇</div>
                                <div v-if="article">{{ article.preTitle==null?'无':article.preTitle }}</div>
                            </div>
                            <div class="shu"></div>
                            <div class="nexpian" style="align-items: flex-end" @click="goMore('n')">
                                <div style="margin-bottom: 40px">下一篇</div>
                                <div v-if="article">
                                    {{ article.nextTitle==null?'无':article.nextTitle }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import newsApi from "@/api/news.js"

    export default {
        data() {
            return {
                article: {
                    news: null
                },
                type: ''
            }
        },
        created() {
            let id = this.$route.query.id
            this.type = this.$route.query.type
            if (this.type == "home") {
                this.getHomeArt(id)
            } else {
                this.getNewDetail(id)
            }
        },
        mounted() {
            this.$parent.$children[0].linkStyle = {color: '#fff'}
            this.$parent.$children[0].loginBtnStyle = {color: '#FBFBFB', border: "1px solid #FBFBFB"}
            this.$forceUpdate()
        },
        beforeDestroy() {
            this.$parent.$children[0].linkStyle = {}
            this.$parent.$children[0].loginBtnStyle = {}
        },
        methods: {
            goRoute(name, index) {
                this.$router.push({
                    name, index
                })
            },
            getNewDetail(id) {
                newsApi.newDetail(id).then(res => {
                    this.article = res.data
                })
            },
            getHomeArt(id) {
                newsApi.homeInfo(id).then(res => {
                    this.article.news = res.data.frontArticle
                    console.log("this.article", this.article)
                })
            },
            goMore(is) {
                let title = this.$route.query.title
                if (this.article.preTitle == null && is == 'p') {
                    return
                }
                if (this.article.nextTitle == null && is == 'n') {
                    return
                }
                this.getNewDetail(is == 'p' ? this.article.preId : this.article.nextId)
            },
        }
    }
</script>

<style scoped lang="scss">

    .nav {
        color: #707070;
        display: flex;
        align-items: center;
        padding: 60px 80px 0px;

        i {
            margin: 0 4px;
        }

        .shu {
            width: 1px;
            height: 20px;
        }

        .time {
            position: relative;
            margin-top: 2px;
            margin-left: 40px;
            display: flex;
            align-items: center;
            &:after {
                content: "";
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 12px;
                background: #707070;
            }
        }
    }

    .active {
        box-sizing: border-box;
        transform: translateY(-155px);
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
        background-color: #fff;
        padding-bottom: 40px;
    }

    .tit {
        padding: 40px 80px 20px;
        letter-spacing: 4px;
        font-size: 30px;
        font-weight: 400;
        color: #1320E8;
    }

    .cbox {
        padding: 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .active-c {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 30px;
        color: #606060;
    }

    .nexpian-box {
        border-top: 1px solid #D1D3D4;
        border-bottom: 1px solid #D1D3D4;
        position: relative;
        box-sizing: border-box;
        margin: 100px 0;
        padding: 0 40px;
        width: 100%;
        height: 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nexpian {
            padding: 0 30px;
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-size: 14px;
            font-weight: 400;
            color: #707070;

            &:hover {
                cursor: pointer;
                color: #1320E8;
            }
        }

        .shu {
            width: 1px;
            height: 100%;
            background-color: #D1D3D4;
            position: absolute;
            left: 50%;
            top: 0;
        }
    }

    .pointer {
        &:hover {
            cursor: pointer;
        }
    }


</style>
